import React from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserFooter = () => {
    const navigate = useNavigate()
    const handleClickBusiness = () => {

        navigate(`/`, { state: { businessText: "businessText" } });


    };
    const handleClickFAQ = () => {

        navigate(`/process`, { state: { faq: "faq" } });


    };
    return (
        <div>
            {/* <footer className="footer">
        <div className="footer-bottom">
          <div className="container">
            <div className="copyright">
              <ul>
                <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                <li><Link to='/terms-conditions'> Terms & Conditions</Link></li>
              </ul>
              <p>Copyright © 2023 Lingvara - all rights reserved.</p>
            </div>
          </div>
        </div>
      </footer> */}
            <footer className="footer">
                <div className="footer-main">
                    <div className="container">
                        <div className="footer-flex">
                            <div className="footer-info">
                                <img src="/images/footer-logo.png" alt="" />
                                <ul className="footer-s-link">
                                    <li>
                                        {" "}
                                        <a href="https://www.linkedin.com/in/lingvara/" target='blank'>
                                            <FontAwesomeIcon icon="fa-brands fa-linkedin" />
                                        </a>{" "}
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/profile.php?id=100094499720697" target='blank'>
                                            <FontAwesomeIcon icon="fa-brands fa-facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/lingvaraofficial/" target="blank">
                                            <FontAwesomeIcon icon="fa-brands fa-instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/@LingvaraOfficial" target='blank'>
                                            <FontAwesomeIcon icon="fa-brands fa-youtube" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://x.com/lingvarainfo" target='blank'>
                                            <img src="/images/twitterx-30.svg" className="rounded xtwitter" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-info">
                                <h6>Company</h6>
                                <ul>
                                    <li>
                                        <Link to='/pricing'>Pricing</Link>
                                    </li>
                                    <li>
                                        <Link to='/about-us'>About us</Link>
                                    </li>
                                    <li>
                                        <Link to='/accessibility'>Accessibility</Link>
                                    </li>
                                    <li>
                                        <Link to='/money-back-guarantee'>Money-back Guarantee</Link>
                                    </li>
                                    <li>
                                        <p style={{ cursor: "pointer" }} onClick={handleClickBusiness}>Business Solutions</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-info">
                                <h6>Other</h6>
                                <ul>
                                    <li>
                                        <Link to='/contact-us'>Contact Us</Link>
                                    </li>
                                    <li>
                                        {/* <p style={{ cursor: "pointer" }} onClick={handleClickFAQ}>FAQs</p> */}
                                        <Link to='/faq'>FAQs</Link>

                                    </li>
                                    <li>
                                        <Link to='/help-support'>Help &amp; Support</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-info">
                                <h6>Connect with us</h6>
                                <ul>
                                    <li>
                                        <a href={`mailto:${"lingvaraofficial@gmail.com"}`}>info@lingvara.com</a>
                                    </li>
                                    {/* <li>
                                        <p>239 Q. Rania St. Office 110,
                                            2762, Alsalt 392, Albalqa, 19110</p>
                                    </li> */}
                                </ul>
                            </div>
                            {/* <div className="footer-info">
                            <h4>Join the newsletter</h4>
                            <p>We spend hours curating the best content just for you for free!</p>
                            <form action="">
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    className="footer-email"
                                />
                                <input type="submit" />
                            </form>
                        </div> */}
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="copyright">
                            <ul>
                                <li>
                                    <Link to='/privacy-policy'>Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to='/terms-conditions'> Terms &amp; Conditions</Link>
                                </li>
                            </ul>
                            <p>Copyright © {new Date().getFullYear()} Lingvara - all rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default UserFooter